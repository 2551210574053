<template>
  <el-dialog :visible.sync="show" :fullscreen="true" :class="[show && 'bounceInUp']">
    <div class="cont scrollbar-v">
      <div class="top-bg">
        <img src="../../../assets/images/concat-bg.png" alt />
        <!-- <div class="top-bg-cont">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            我们追求自由，更懂得自律；
            <br />我们关注想法，更讲究实战；
            <br />我们紧随时代，更要超越时代。
          </div>
          <div></div>
        </div>-->
      </div>
      <div class="top">
        <div class="logo">
          <img src="../../../assets/images/logo.png" alt />
        </div>
        <div class="text">
          <h3>联系我们</h3>
          <hr class="white-line" />
          <span>CONTACT US</span>
        </div>
      </div>
      <div class="c">
        <div>
          <span class="tit">商务合作</span>
          <p class="email">bd@mixdo.net</p>
        </div>
        <div>
          <span class="tit">简历投递</span>
          <p class="email">hr@mixdo.net</p>
          <div class="icons">
            <span>
              <img src="../../../assets/images/icon1.png" alt />
            </span>
            <span>
              <img src="../../../assets/images/icon2.png" alt />
            </span>
            <span>
              <img src="../../../assets/images/icon3.png" alt />
            </span>
          </div>
        </div>
      </div>
      <footers></footers>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { footers } from "@/components/web/footers";

export default {
  components: { footers },
  computed: {
    show: {
      get() {
        return this.$store.state.pagefiveJoinusShow;
      },
      set() {
        this.$store.dispatch("setPagefiveJoinusShowC", false);
      },
    },
  },
  data() {
    return {
      usData: {},
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      // axios.get("/api/We").then((rs) => {
      //   if (rs.data.Success) {
      //     this.usData = rs.data.Data.Data;
      //     console.log(this.usData);
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
  .cont {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    background: $bgColor;
    overflow: hidden;
    .top {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      .logo {
        padding: 2.6% 4.2% 3.5%;
      }

      .text {
        padding: 4% 8.4%;

        h3 {
          margin: 0;
          font-size: 60px;
        }

        hr {
          width: 80px;
        }

        & > span {
          display: block;
          padding: 0.5% 0;
          opacity: 0.6;
          font-size: 16px;
        }
      }

      &-bg {
        position: relative;

        & > img {
          width: 100%;
          display: block;
          margin: 0;
        }
        &-cont {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          display: flex;
          flex-wrap: wrap;
          background: rgba(192, 45, 32, 0.15);

          & > div {
            display: flex;
            flex-basis: 25%;
            height: 50%;
            background: transparent;
            color: #cdb7b5;
            opacity: 0;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            transition: all 0.6s;

            &:hover {
              background: $red;
              opacity: 1;
            }
          }
        }
      }
    }
    .c {
      display: flex;
      padding: 5% 8.4%;
      justify-content: space-between;

      & > div {
        flex: 1;
        padding: 4% 0;

        & + div {
          padding-left: 6%;
          border-left: 1px solid #fff;
        }
        .tit {
          font-size: 36px;
        }
        .email {
          margin: 0;
          padding: 4% 0;
          font-size: 68px;
        }
        .icons {
          display: flex;
          span {
            display: inline-block;
            img {
              width: 30px;
              -webkit-user-drag: none;
            }
            & + span {
              padding-left: 35px;
            }
          }
        }
      }
    }

    @media (max-width: 1440px) {
      .top {
        .logo {
          img {
            height: 45px;
          }
        }
        .text {
          h3 {
            font-size: 50px;
          }

          & > span {
            padding-top: 0;
            font-size: 14px;
          }
        }
      }
      .c {
        padding-top: 3.5%;
        padding-bottom: 3.5%;

        & > div {
          padding: 3% 0;

          .tit {
            font-size: 24px;
          }
          .email {
            font-size: 52px;
          }
          .icons {
            span {
              img {
                width: 24px;
              }
              & + span {
                padding-left: 25px;
              }
            }
          }
        }
      }
    }
  }
</style>