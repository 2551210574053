<template>
  <div class="swiper-cont">
    <div class="not-data" v-show="swiperData.length == 0">暂无数据</div>
    <template v-show="swiperData.length > 0">
      <div class="collapse-swiper">
        <swiper ref="pagetwoSwiper" :options="options" class="swiper-no-swiping">
          <swiper-slide v-for="item in swiperData" :key="item.id">
            <div class="cont">
              <div class="img-cont">
                <img class="normal" :src="item.src" alt />
                <img class="hover" :src="item.hoverSrc" alt />
              </div>
              <div class="txt-box">
                <h4>{{ item.tit }}</h4>
                <p>{{ item.subtit }}</p>
                <span class="more com-more-add" @click="more1(item)"></span>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="swiper-prev" v-show="arrowIsShow">
          <span></span>
        </div>
        <div class="swiper-next" v-show="arrowIsShow">
          <span></span>
        </div></div
    ></template>
  </div>
</template>

<script>
import CaseListMixin from './caseListMixin';
export default {
  name: 'swiperH',
  mixins: [CaseListMixin],
  props: {
    typeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: {
        init: false,
        slidesPerView: 3,
        navigation: {
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next'
        }
      },
      showMore: false
    };
  },
  watch: {
    swiperData(nv) {
      if (nv) {
        this.swiper.init();
        this.$nextTick(() => {
          this.slideHover();
        });
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.pagetwoSwiper.swiper;
    },
    arrowIsShow() {
      return this.swiperData.length > 3;
    }
  },
  mounted() {},
  methods: {
    slideHover() {
      let slides = this.swiper.el.getElementsByClassName('swiper-slide');

      slides.forEach(element => {
        let swiperW = parseFloat(element.style.width);
        let prevDom = element.previousElementSibling || null;

        element.onmouseover = () => {
          if (!prevDom) return;

          if (prevDom && prevDom.className.indexOf('swiper-slide-next') >= 0) {
            prevDom.previousElementSibling.style.marginLeft = -swiperW + 'px';
            prevDom.previousElementSibling.style.zIndex = 2;
          }
        };

        element.onmouseleave = () => {
          if (prevDom && prevDom.className.indexOf('swiper-slide-next') >= 0) {
            prevDom.previousElementSibling.style.marginLeft = 0;
            prevDom.previousElementSibling.style.zIndex = 0;
          }
        };
      });
    },
    more1({ id, Type }) {
      if (Type === 1) {
        this.$store.dispatch('setPagetwoSlideShowC', { visible: true, id });
      } else {
        this.$store.dispatch('setPagetwoMoreSlideShowC', { visible: true, id });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pagetwoSwiper.scss';
.swiper-cont {
  height: 100%;
}
</style>
