<template>
  <el-dialog :visible.sync="show1" :fullscreen="true" :class="[show1 && 'bounceInUp']">
    <section class="slide-detail scrollbar-v">
      <header class="logo">
        <img src="../../../assets/images/red-logo.png" alt />
      </header>
      <section class="main">
        <h3 class="h3">{{ Title }}</h3>
        <h4 class="sub-title">
          <span>{{ CustomerName }}</span>
          <span>
            <img src="../../../assets/images/weixin.png" alt />
            <img src="../../../assets/images/weibo.png" alt />
          </span>
        </h4>
        <hr class="gray-line m0" />
        <!-- <div class="cont" v-html="contentDetail"></div> -->
        <div class="cont" v-html="contentDom"></div>
      </section>
    </section>
  </el-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      contentDetail: '',
      contentDom: '',
      Title: '',
      Subtitle: '',
      CustomerName: ''
    };
  },
  watch: {
    show1(val) {
      if (val) {
        axios.get(`/api/Cases?Id=${this.caseId}`).then(res => {
          if (res.data.Success) {
            this.contentDom = res.data.Data.MainBody;
            this.Title = res.data.Data.Title;
            this.Subtitle = res.data.Data.Subtitle;
            this.CustomerName = res.data.Data.CustomerName;
          }
        });
      }
    }
  },
  computed: {
    caseId() {
      return this.$store.state.currentCaseId;
    },
    show1: {
      get() {
        return this.$store.state.pagetwoSlideShow;
      },
      set() {
        this.$store.dispatch('setPagetwoSlideShowC', { visible: false, id: 0 });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-detail {
  height: 100%;
  padding: 2.5% 0;
  background: $bgColor;

  .logo {
    padding: 0 4.2%;
  }

  .main {
    padding: 0 13%;

    & > .h3 {
      margin: 0;
      padding-top: 7.7%;
      font-size: 32px;
    }
    & > .sub-title {
      display: flex;
      margin: 0;
      padding-top: 3.2%;
      padding-bottom: 1.8%;
      font-size: 24px;
      font-weight: normal;
      color: $defaultColor;
      justify-content: space-between;
    }
    img {
      width: 24px;
      & + img {
        margin-left: 20px;
      }
    }

    .cont {
      padding-top: 4%;
      color: $defaultColor;
    }
  }

  @media (max-width: 1440px) {
    .logo {
      img {
        height: 45px;
      }
    }
    .main {
      & > .h3 {
        padding-top: 5.7%;
        font-size: 24px;
      }
      & > .sub-title {
        padding-top: 2.2%;
        padding-bottom: 1%;
        font-size: 18px;
      }
      img {
        width: 20px;
        & + img {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
