<template>
  <div class="pc-cont scrollbar-h">
    <div class="home">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-no-swiping">
        <swiper-slide v-for="item in swiperData" :key="item.id">
          <component @menuItemClick="menuItemClick" :is="item.comp" :isPage1="isPage1"></component>
        </swiper-slide>
        <div class="swiper-pagination pagination" slot="pagination"></div>
      </swiper>

      <!-- dialog -->
      <pagetwo-more></pagetwo-more>
      <pagetwo-more-slide></pagetwo-more-slide>
      <pagetwo-slide></pagetwo-slide>
      <pagefive-joinus></pagefive-joinus>
    </div>
  </div>
</template>

<script>
import { page1, page2, page3, page4, page5 } from './index';
import { pagetwoMore, pagetwoMoreSlide, pagetwoSlide, pagefiveJoinus } from '@/components/web/dialog';

export default {
  name: 'Home',
  data() {
    let _this = this;
    return {
      isPage1: true,
      swiperOptions: {
        height: window.innerHeight,
        direction: 'vertical',
        mousewheel: true,
        keyboard: true, //键盘
        speed: 800,
        releaseOnEdges: true,
        pagination: {
          el: '.pagination',
          clickable: true,
          bulletClass: 'ver-bullet',
          bulletActiveClass: 'ver-bullet-active'
        },
        on: {
          slideChange() {
            _this.isPage1 = this.activeIndex == 0 ? true : false;
          }
        }
      },
      swiperData: [
        { id: 0, comp: 'page1' },
        { id: 1, comp: 'page2' },
        { id: 2, comp: 'page3' },
        { id: 3, comp: 'page4' },
        { id: 4, comp: 'page5' }
      ]
    };
  },
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    pagetwoMore,
    pagetwoMoreSlide,
    pagetwoSlide,
    pagefiveJoinus
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    menuItemClick(item) {
      switch (item.menu) {
        case '首页':
          this.swiper.slideTo(0);
          break;
        case '案例':
          this.swiper.slideTo(1);
          break;
        case '联系我们':
          this.swiper.slideTo(4);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pc-cont {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.home {
  height: 100%;
  min-width: 1366px;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  position: fixed;
}

/deep/ .ver-bullet {
  display: block;
  width: 2px;
  height: 40px;
  margin: 6px 0;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  transition: height 0.2s, background 0.4s;
  cursor: pointer;
  &-active,
  &:focus {
    height: 56px;
    background: $red;
    outline: none;
  }
}
</style>
