<template>
  <div class="page1">
    <home-header @menuItemClick="menuItemClick"></home-header>

    <swiper-h :isPage1="isPage1" :index="swiperHIndex"></swiper-h>
  </div>
</template>

<script>
import { homeHeader } from "@/components/web/header";
import swiperH from "./swiperH.vue";

export default {
  name: "page1",
  props: {
    isPage1: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      swiperHIndex: "0",
    };
  },
  components: { homeHeader, swiperH },
  methods:{
    menuItemClick(item){
      this.$emit('menuItemClick',item)
    }
  }
};
</script>

<style lang="scss" scoped>
.page1 {
  position: relative;
  height: 100%;
}
</style>