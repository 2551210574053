<template>
  <el-dialog :visible.sync="show" :fullscreen="true" :class="[show && 'bounceInRight']">
    <div class="box">
      <div class="main">
        <div class="logo">
          <img src="../../../assets/images/logo.png" alt />
        </div>
        <div class="swiper-box">
          <div class="not-data" v-if="casePageSize === 0">暂无数据</div>
          <el-carousel v-show="casePageSize !== 0" :height="carouselHeight" direction="vertical" :autoplay="false" trigger="click">
            <el-carousel-item v-for="(items, index) in caseList" :key="index" indicator-position="outside">
              <div class="item-box clearfix" :class="slideItemShowHide">
                <div class="item" v-for="(item, indexs) in items" :key="item.key" @mouseover="itemHover(items, indexs)" @mouseleave="slideItemShowHide = ''">
                  <div class="img-cont">
                    <img class="normal" :src="item.src" />
                    <img class="hover" :src="item.hoverSrc" />
                  </div>
                  <div class="txt-box">
                    <h4>{{ item.tit }}</h4>
                    <p>{{ item.subtit }}</p>
                    <span class="more com-more-add" @click="more(item)"></span>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <side-nav @sideNavClick="sideNavClick" :sideNav="sideNav"></side-nav>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios';
import { sideNav } from '@/components/web/nav';
import CaseListMixin from '../../../views/web/Home/page2/caseListMixin';

export default {
  mixins: [CaseListMixin],
  data() {
    return {
      PageSize: 999,
      sideNav: [],
      morePageSwiper: {
        direction: 'vertical',
        height: window.clientHeight * 0.7,
        pagination: {
          el: '.paginationaa',
          clickable: true
        }
      },
      slideItemShowHide: ''
    };
  },
  components: { sideNav },
  computed: {
    show: {
      get() {
        return this.$store.state.pagetwoMoreShow;
      },
      set() {
        this.$store.dispatch('setPagetwoMoreShowC', false);
      }
    },
    carouselHeight() {
      return window.innerHeight * 0.7 + 'px';
    },
    caseList() {
      if (this.casePageSize == 1) {
        return [this.swiperData];
      } else {
        let data = [];
        for (let i = 0; i < this.casePageSize; i++) {
          data.push([]);
          for (let j = 0; j < 5; j++) {
            if (i * 5 + j === this.swiperData.length) return data;
            data[i].push(this.swiperData[i * 5 + j]);
          }
        }
        return data;
      }
    },
    casePageSize() {
      return Math.ceil(this.swiperData.length / 5);
    }
  },
  mounted() {
    this.getCustoms();
    this.getBusiness();
  },
  methods: {
    itemHover(items, index) {
      if (items.length <= 4) this.slideItemShowHide = '';
      else this.slideItemShowHide = index < 4 ? 'hideLast' : 'hideFirst';
    },
    more({ id, Type }) {
      if (Type === 1) this.$store.dispatch('setPagetwoSlideShowC', { visible: true, id });
      else this.$store.dispatch('setPagetwoMoreSlideShowC', { visible: true, id });
    },
    async getCustoms() {
      const params = { Name: '', PageIndex: 1, PageSize: 999 };
      await axios.get('/api/Customer/Page', { params }).then(rs => {
        if (rs.data.Success) {
          let rows = rs.data.Data.Data || [];
          rows.forEach(element => {
            element.key = `custom_${element.Id}`;
          });
          this.sideNav[0] = rows;
        }
      });
    },
    async getBusiness() {
      await axios.get('/api/BusinessType?Name').then(rs => {
        if (rs.data.Success) {
          let rows = rs.data.Data || [];
          rows.forEach(element => {
            element.key = `buType_${element.Id}`;
          });
          this.sideNav[1] = rows;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  height: 100%;
  overflow: hidden;
  background: $red;
  /deep/ .paginationaa {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.main {
  height: 100%;
  margin-right: 50px;
  padding: 0 4.3%;
}
.logo {
  padding: 2.6% 0 3.5%;
}
.swiper-box {
  position: relative;
  height: 70%;
  margin-right: -30px;
  & > div {
    padding-right: 30px;
  }
  .swiper-container {
    height: 100%;
  }
  .pagination {
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
// 内容区域样式
.item-box {
  height: 100%;
  width: 100%;
  &:hover {
    & > .item {
      &::after {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  & > .item {
    position: relative;
    float: left;
    width: 20%;
    height: 100%;
    color: #fff;
    background: $bgColor;
    overflow: hidden;
    transition: all 0.6s;
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: '';
      transition: all 0.3s;
    }
    .img-cont {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      & > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 0.6s;
        &.normal {
          opacity: 1;
        }
        &.hover {
          opacity: 0;
        }
      }
    }
    .txt-box {
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 0;
      height: 0;
      overflow: hidden;
      transition: height 0.1s;
      h4,
      p {
        width: 0;
        white-space: nowrap;
        overflow: hidden;
      }
      h4 {
        margin: 0;
        padding: 2% 0 2.2% 4.3%;
        font-size: 32px;
      }
      p {
        margin: 0;
        padding: 1.1% 0 1.1% 4.3%;
        font-size: 20px;
      }
      .more {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        z-index: 4;
      }
    }
    &:hover {
      width: 40%;
      &::after {
        background: transparent;
      }
      .img-cont {
        width: calc(100% - 20px);
        height: 60%;
        margin: 10px;
        border-radius: 10px;
        & > img {
          &.normal {
            opacity: 0;
          }
          &.hover {
            opacity: 1;
          }
        }
      }
      .txt-box {
        height: 35%;
        h4,
        p {
          animation: widthShow 0.6s forwards;
          animation-delay: 0.2s;
        }
      }
    }
  }
}
.hideFirst {
  .item:first-child {
    width: 0;
    height: 100%;
    opacity: 0;
  }
}
.hideLast {
  .item:last-child {
    width: 0;
    height: 100%;
    opacity: 0;
  }
}
@media (max-width: 1440px) {
  .logo {
    img {
      height: 45px;
    }
  }
  .item-box {
    & > .item {
      .txt-box {
        h4 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
