<template>
  <swiper ref="pageOneSwiper" :options="swiperOneOptions">
    <swiper-slide>
      <!-- swiper slide 1 -->
      <div class="cont cont-1">
        <div class="ani">
          <div class="cont__cont">
            专注服务
            <br />世界500强、央企及金融业。
          </div>
          <hr class="white-line w170" />
          <p class="serve-address">
            <span class="icon"></span>
            <span class="txt">北京</span>
            <span class="txt">深圳</span>
            <span class="txt">广州</span>
            <span class="txt">香港</span>
          </p>
        </div>
      </div>
    </swiper-slide>
    <!-- swiper slide 2 -->
    <swiper-slide>
      <div class="cont cont-2">
        <div class="ani">
          <div class="cont__block">
            <div class="cont__block__item">
              <span class="font-large">10</span>
              年的专注
            </div>
            <div class="cont__block__item">
              <span class="font-large">30 +</span>
              品牌服务经验
            </div>
            <div class="cont__block__item">
              <span class="font-large">500 +</span>
              个金融服务案例
            </div>
          </div>
          <hr class="white-line w170" />
          <p class="serve-address">
            <span class="icon"></span>
            <span class="txt">北京</span>
            <span class="txt">深圳</span>
            <span class="txt">广州</span>
            <span class="txt">香港</span>
          </p>
        </div>
      </div>
    </swiper-slide>
    <!-- swiper slide 3 -->
    <swiper-slide>
      <div class="cont cont-3">
        <div class="ani">
          <div class="cont__cont">持续 ；专注</div>
          <ul class="cont__list">
            <li v-for="(item, index) in listItems" :key="index">
              <span class="time">{{ item.time }}</span>
              {{ item.txt }}
            </li>
          </ul>
          <hr class="white-line w170" />
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination text-right pagination-h" slot="pagination"></div>
  </swiper>
</template>

<script>
import axios from 'axios';

export default {
  name: 'swiperH',
  props: {
    isPage1: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const vueSelf = this;
    return {
      swiperOneOptions: {
        direction: 'horizontal',
        autoplay: {
          delay: 4000,
        },
        keyboard: true,
        loop: true,
        pagination: {
          el: '.pagination-h',
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return `<div> ${current < 10 ? '0' + current : current} <div class='time-pro'><div></div></div> ${current + 1 > total ? '01' : current + 1 < 10 ? '0' + (current + 1) : current + 1}</div>`;
          },
        },
        on: {
          async init() {
            const result = await vueSelf.getBannerList();
            const bannerList = result.data.Data.Data;
            this.slides[this.activeIndex].className += ' ani-left-mask';
            for (let i = 0; i < this.slides.length; i++) {
              let swiperSlideIndex = this.slides[i].dataset.swiperSlideIndex;
              this.slides[i].firstChild.style.backgroundImage = `url(${bannerList[swiperSlideIndex].BannerURL})`;
            }
          },
          transitionStart() {
            for (let i = 0; i < this.slides.length; i++) {
              if (this.slides[i].className.indexOf('ani-left-mask') > 0) {
                this.slides[i].className = this.slides[i].className.replace(' ani-left-mask', '');
              }
            }
          },
          transitionEnd() {
            this.slides[this.activeIndex].className += ' ani-left-mask';
          },
        },
      },
      listItems: [
        { time: '2023', txt: '第9届TMA案例类-内容营销类奖' },
        { time: '2023', txt: '第9届TMA案例类-娱乐营销类品牌代言奖' },
        { time: '2023', txt: '第9届TMA行业类-品牌代言奖' },
        { time: '2023', txt: '第六届DMAA金融类奖' },
        { time: '2023', txt: '第23届IAI传鉴国际广告奖' },
        { time: '2022', txt: '第13届金鼠标数字营销大赛跨媒体整合类奖' },
        { time: '2022', txt: '第13届金鼠标数字营销大赛话题营销类奖' },
        { time: '2022', txt: '第13届金鼠标数字营销大赛评选委员会 终审评委' },
        { time: '2021', txt: ' ECI AWARDS 国际艾奇奖-营销创新类奖项' },
        { time: '2021', txt: '中国数字化转型与创新评选-年度网络创新产品奖' },
        { time: '2020', txt: '第十一届虎啸奖内容营销类优秀奖' },
        { time: '2020', txt: '第十一届虎啸奖金融/保险之产品与服务类等级奖' },
        { time: '2020', txt: '第20届IAI国际广告奖金融营销/银行金奖' },
        { time: '2020', txt: '第11届金鼠标数字营销大赛年度数字营销创新力代理公司' },
        { time: '2019', txt: '数字化转型与创新评选“年度数字金融解决方案”奖' },
        { time: '2019', txt: '中国国际旅游交流会最佳广告奖' },
        { time: '2019', txt: '中国国际旅游交流会最佳组织奖' },
        { time: '2019', txt: '中国国际旅游交流会最佳展台奖' },
        { time: '2019', txt: '深圳国际文化产业博览会优秀展示奖' },
        { time: '2019', txt: '金远奖全场大奖' },
        { time: '2016', txt: '中国金融营销金栗子奖最佳传播奖' },
        { time: '2012', txt: '广州日报华文报纸广告奖' },
        { time: '2011', txt: '中国金融品牌十大年度广告' },
      ],
      bannerList: [],
    };
  },
  watch: {
    isPage1(nv) {
      if (nv) {
        this.swiper.autoplay.start();
      } else {
        this.swiper.autoplay.stop();
      }
    },
  },
  computed: {
    swiper() {
      return this.$refs.pageOneSwiper.swiper;
    },
  },
  methods: {
    async getBannerList() {
      const params = { Disable: false, PageIndex: 1, PageSize: 999 };
      return await axios.get('/api/Banner/Page', { params: params });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  height: 100%;
}
// 第一页内容
.cont {
  height: 100%;
  display: flex;
  padding: $headerHeight 13% 0;
  align-items: center;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% 100%;

  & > div {
    width: 0;
    overflow: hidden;
    white-space: nowrap;

    hr {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  &__cont {
    font-size: 70px;
  }
}
.serve-address {
  min-width: 400px;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 24px;

  .icon,
  .txt {
    display: inline-block;
    margin-right: 15px;
  }
  .icon {
    width: 45px;
    height: 45px;
    background: url(../../../../assets/images/address-icon.png) no-repeat center;
    background-size: 100% 100%;
  }
}
.cont__block {
  padding-bottom: 10px;

  &__item {
    display: inline-block;
    width: 190px;
    height: 100px;
    margin-right: 30px;
    border: 1px solid #fff;
    text-align: center;
    font-size: 20px;
  }
  .font-large {
    display: block;
    padding: 3px 0 2px;
    font-size: 44px;
  }
}
.cont__list {
  padding: 20px 0 0;
  li {
    line-height: 30px;
    .time {
      display: inline-block;
      padding-right: 15px;
    }
  }
}

// 分页
/deep/ .swiper-pagination-custom > div {
  padding-right: 15px;
}
// 时间条
/deep/ .time-pro {
  position: relative;
  display: inline-block;
  top: -3px;
  width: 100px;
  height: 6px;
  margin: 0 5px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;

  & > div {
    display: block;
    width: 0%;
    height: inherit;
    background: $red;
    opacity: 0.8;
    -moz-animation: mozWidthPer 4.2s linear forwards;
    -webkit-animation: webkitWidthPer 4.2s linear forwards;
    animation: widthPer 4.2s linear forwards;
  }
}

@media (max-width: 1440px) {
  $headerHeight: 90px;

  .cont {
    padding-top: $headerHeight;
    &__cont {
      font-size: 50px;
    }
  }
  .serve-address {
    font-size: 18px;
    .icon {
      width: 30px;
      height: 30px;
    }
  }

  .cont__block {
    &__item {
      width: 170px;
      height: 87px;
      font-size: 16px;
    }
    .font-large {
      font-size: 40px;
    }
  }

  .cont__list {
    li {
      line-height: 26px;
    }
  }
}
</style>