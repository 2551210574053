<template>
  <div class="page4">
    <div class="cont">
      <h3>我们的客户</h3>
      <hr class="white-line" />
      <span>OUR CLIENT</span>

      <div class="logos">
        <span v-for="item in logos" :key="item.id">
          <img :src="item.ImgUrl" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'page4',
  data() {
    return {
      logos: []
    };
  },
  mounted() {
    this.getCustoms();
  },
  methods: {
    getCustoms() {
      const params = { Name: '', PageIndex: 1, PageSize: 999 };
      axios.get('/api/Customer/Page', { params }).then(rs => {
        if (rs.data.Success) {
          this.logos = rs.data.Data.Data || [];
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page4 {
  height: 100%;
  padding-right: 13%;
}
.cont {
  height: 100%;
  padding: 0 4.2%;
  background: $red;

  & > h3 {
    margin: 0;
    padding-top: 6%;
    font-size: 60px;
  }
  & > hr {
    width: 80px;
  }
  & > span {
    display: block;
    padding: 0.5% 0;
    opacity: 0.6;
    font-size: 16px;
  }
}
.logos {
  display: flex;
  width: 100%;
  max-height: 75%;
  padding: 2% 0;
  flex-wrap: wrap;
  overflow: hidden;

  & > span {
    display: block;
    height: 85px;
    width: 20%;
    & > img {
      height: 100%;
      max-height: 44px;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-drag: none;
    }

    &:hover > img {
      animation: pulse 0.4s;
    }
  }
}

// 动画效果
.swiper-slide-active .logos span {
  animation: scaleX 1s;
}

@media (max-width: 1440px) {
  .cont {
    & > h3 {
      font-size: 50px;
    }
    & > span {
      padding-top: 0;
      padding-bottom: 1.5%;
      font-size: 14px;
    }
  }
  .logos {
    & > span {
      height: 70px;
      & > img {
        max-height: 32px;
      }
    }
  }
}
</style>
