<template>
  <div class="page2 clearfix">
    <!-- left swiper -->
    <div class="fl l"><swiper-h :typeId="typeId"></swiper-h></div>

    <!-- right cont nav -->
    <div class="fr r">
      <h3>经典案例。</h3>
      <hr class="white-line" />
      <span>CASE</span>
      <ul class="nav">
        <li v-for="item in customItems" :key="item.key" :class="[checkNavItem == item.key ? 'active' : '']" @click="navCheckChange(item)">
          <a href="javascript:void(0);">{{ item.txt }}</a>
        </li>
        <li class="last">
          <a href="javascript:void(0);" class="com-more" @click="more"> MORE <span class="icon"></span> </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import swiperH from './swiperH';

export default {
  name: 'page2',
  data() {
    return {
      customItems: [{ id: '_0', txt: '全部', key: '_0' }],
      checkNavItem: '_0',
      typeId: ''
    };
  },
  components: { swiperH },
  mounted() {
    this.getCustoms();
  },
  methods: {
    more() {
      this.$store.dispatch('setPagetwoMoreShowC', true);
    },
    getCustoms() {
      const params = { Name: '', PageIndex: 1, PageSize: 4 };
      axios.get('/api/Customer/Page', { params: params }).then(rs => {
        if (rs.data.Success) {
          let rows = rs.data.Data.Data;
          rows.forEach(ele => {
            let { Id: id, Name: txt } = ele;
            this.customItems.push({ ...ele, id, txt, key: 'custom_' + id });
          });
        }
      });
    },
    navCheckChange(item) {
      if (item.key == this.checkNavItem) return false;
      this.typeId = item.key == '_0' ? '' : item.id + '';
      this.checkNavItem = item.key;
    }
  }
};
</script>
<style lang="scss" scoped>
.page2 {
  height: 100%;
  display: flex;
  & > .l {
    width: 66%;
  }
  & > .r {
    width: 34%;
    padding: 15px 0 15px 11.5%;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 25% 0 15px;
      font-size: 64px;
      font-weight: normal;
      letter-spacing: 2px;
    }
    hr {
      width: 80px;
    }
    & > span {
      letter-spacing: 2px;
      font-size: 16px;
      color: $defaultColor;
    }
    .nav {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      li:not(.last) a {
        position: relative;
        display: block;
        line-height: 80px;
        color: $defaultColor;
        font-size: 26px;
        &:hover {
          color: $color;
        }
      }
      li:not(.last).active a {
        color: $color;
      }
      .last {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
  }
  @media (max-width: 1440px) {
    & > .r {
      h3 {
        margin-top: 20%;
        margin-bottom: 0px;
        font-size: 50px;
      }
      & > span {
        letter-spacing: 1px;
        font-size: 14px;
      }
      .nav {
        li:not(.last) a {
          line-height: 60px;
          font-size: 20px;
        }
        .last {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
