<template>
  <el-dialog :visible.sync="show" :fullscreen="true" :class="[show && 'bounceInUp']">
    <div class="cont">
      <div class="logo">
        <img src="../../../assets/images/red-logo.png" alt />
      </div>
      <div class="main">
        <div class="l">
          <h3 class="h3">{{ Title }}</h3>
          <h4 class="sub-title">
            <span>{{ Subtitle }}</span>
            <span>
              <img src="../../../assets/images/weixin.png" alt />
              <img src="../../../assets/images/weibo.png" alt />
            </span>
          </h4>
          <hr class="gray-line m0" />
          <div class="cont">
            {{ Describe }}
          </div>
        </div>
        <div class="r">
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="(item, index) in ImgArray" :key="index">
              <div class="img-cont">
                <img :src="item" alt />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      Describe: '',
      contentDom: '',
      Title: '',
      Subtitle: '',
      CustomerName: '',
      ImgArray: []
    };
  },
  watch: {
    show(val) {
      if (val) {
        axios.get(`/api/Cases?Id=${this.caseId}`).then(res => {
          if (res.data.Success) {
            this.Describe = res.data.Data.Describe;
            this.Title = res.data.Data.Title;
            this.Subtitle = res.data.Data.Subtitle;
            this.CustomerName = res.data.Data.CustomerName;
            this.ImgArray = res.data.Data.ImgArray;
          }
        });
      }
    }
  },
  computed: {
    caseId() {
      return this.$store.state.currentCaseId;
    },
    show: {
      get() {
        return this.$store.state.pagetwoMoreSlideShow;
      },
      set() {
        this.$store.dispatch('setPagetwoMoreSlideShowC', { visible: false, id: 0 });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cont {
  display: flex;
  height: 100%;
  // padding: 0 4.3%;
  flex-direction: column;
  background: $bgColor;
  overflow: hidden;
  white-space: pre-line;
  .logo {
    flex: 0;
    padding: 2.6% 4.2% 3.5%;
  }

  .main {
    display: flex;
    flex: 1;
    height: 70%;
    padding: 0 12.5%;

    & > div {
      height: 100%;
      overflow: hidden;
    }

    .l {
      flex-basis: 60%;
      width: 60%;
      padding-right: 8%;

      & > .h3 {
        margin: 0;
        font-size: 32px;
      }
      & > .sub-title {
        display: flex;
        margin: 0;
        padding-top: 3.2%;
        padding-bottom: 1.8%;
        font-size: 20px;
        font-weight: normal;
        color: $defaultColor;
        justify-content: space-between;
      }

      img {
        width: 24px;
        & + img {
          margin-left: 20px;
        }
      }

      .cont {
        padding-top: 4%;
        color: $defaultColor;
      }
    }
    .r {
      flex-basis: 40%;
      width: 40%;
    }
    .img-cont {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .el-carousel {
      height: 100%;

      /deep/ & > div {
        height: 85%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .cont {
    .logo {
      img {
        height: 45px;
      }
    }

    .main {
      .l {
        & > .h3 {
          padding-top: 5.7%;
          font-size: 24px;
        }
        & > .sub-title {
          padding-top: 2.2%;
          padding-bottom: 1%;
          font-size: 14px;
        }
        img {
          width: 20px;
          & + img {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>
