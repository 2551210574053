<template>
  <div class="page3 clearfix">
    <div class="fl l">
      <a href="javascript:void(0);" class="com-more" @click="more">
        MORE
        <span class="icon"></span>
      </a>
    </div>
    <div class="fr r">
      <!-- right items -->
      <div
        class="item"
        v-for="(items, index) in infoList"
        :key="index"
        @mouseover="videoPlay($event)"
        @mouseout="videoStop($event)"
      >
        <img
          class="normal"
          :src="require(`../../../../assets/images/${items.imgSrc}`)"
          alt
        />

        <video
          width="100%"
          id="video"
          loop
          preload
          muted
          x-webkit-airplay="true"
          airplay="allow"
          webkit-playsinline="true"
          playsinline="true"
          :src="items.videoSrc"
          class="hover"
        ></video>

        <div>
          <span>
            <img
              :src="require(`../../../../assets/images/${items.logoSrc}`)"
              alt
            />
          </span>
          <h4 v-html="items.title"></h4>
          <p>{{ items.subTit }}</p>
        </div>
        <!-- <span class="com-more-add"></span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page3",
  data() {
    return {
      infoList: [
        {
          imgSrc: "img04.png",
          videoSrc: "https://zww1.oss-cn-shenzhen.aliyuncs.com/mixdo_protal/video/index-video1.mp4",
          logoSrc: "other-logo1.png",
          title:
            "<span>华侨城春节贺岁宣传片 | 来华侨城过中</span><span>国年</span>",
          subTit: "你有多久没有陪孩子一起玩耍了",
        },
        {
          imgSrc: "img05.png",
          videoSrc: "https://zww1.oss-cn-shenzhen.aliyuncs.com/mixdo_protal/video/index-video2.mp4",
          logoSrc: "other-logo2.png",
          title:
            "<span>新中国成立70周年 | 麦道和招行一起，</span><span>做了一件很朋克的事情</span>",
          subTit: "你有多久没有陪孩子一起玩耍了",
        },
      ],
    };
  },
  methods: {
    videoPlay(even) {
      even.target.tagName == "VIDEO" && even.target.play();
    },
    videoStop(even) {
      even.target.tagName == "VIDEO" && even.target.pause();
    },
    more() {
      this.$store.dispatch("setPagetwoMoreShowC", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.page3 {
  height: 100%;

  & > div {
    height: 100%;
  }

  .l {
    display: flex;
    width: 22%;
    padding-left: 4.2%;
    align-items: center;
  }
  .r {
    width: 78%;

    .item {
      position: relative;
      height: 50%;
      overflow: hidden;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        transition: background 0.3s;
      }

      & > img,
      & > video {
        position: absolute;
        left: 0;
        top: 0;
      }

      & > img {
        width: 100%;
        height: 100%;
      }

      & > .normal {
        opacity: 1;
      }
      & > .hover {
        opacity: 0;
      }

      & > div {
        position: absolute;
        display: flex;
        left: 0;
        top: 15%;
        height: 75%;
        margin: 0 0 0 3%;
        overflow: hidden;
        animation: widthShow 0.4s linear forwards;
        white-space: nowrap;
        flex-direction: column;
        justify-content: space-between;
        z-index: 2;

        & > h4 {
          margin: 0;
          line-height: 1.6;
          font-size: 32px;
          span {
            display: block;
          }
        }
        & > p {
          margin: 0;
          font-size: 18px;
        }
      }

      .com-more-add {
        position: absolute;
        left: 50%;
        bottom: -100%;
        background: $red;
        border-color: $red;
        overflow: hidden;
        opacity: 0;
        transition: all 0.5s;
      }

      &:hover {
        &::before {
          opacity: 0;
        }
        & > .normal {
          opacity: 0;
        }
        & > .hover {
          opacity: 1;
        }
        & > div {
          animation: widthHide 0.4s linear forwards;
          white-space: nowrap;
        }
        .com-more-add {
          bottom: 8%;
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page3 {
    .r {
      .item {
        & > div {
          & > span {
            img {
              height: 80%;
            }
          }
          & > h4 {
            margin-top: -3%;
            font-size: 26px;
          }
          & > p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>