<template>
  <div class="side" :class="{ open: isHover }">
    <aside class="side-nav scrollbar-v" @mouseover="isHover = true" @mouseleave="isHover = false" :style="{ 'overflow-y': isHover ? 'auto' : 'hidden!important' }">
      <span class="side-bar"> <span></span> <span></span> <span></span> </span>
      <dl class="side-nav__list">
        <dt>客户</dt>
        <template v-for="item in sideNav[0]">
          <dd :key="item.key" :class="[checkCustom.key == item.key ? 'active' : '']" @click="navCheckChange(item, '0')">
            <span>{{ item.Name }}</span>
          </dd>
        </template>

        <dt>业务</dt>
        <template v-for="item in sideNav[1]">
          <dd :key="item.key" :class="[checkBusiness.key == item.key ? 'active' : '']" @click="navCheckChange(item, '1')">
            <span>{{ item.Name }}</span>
          </dd>
        </template>
      </dl>
    </aside>
  </div>
</template>
<script>
export default {
  name: 'sideNav',
  props: {
    sideNavTit: {
      type: String,
      default: ''
    },
    sideNav: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      isHover: false,
      checkCustom: { id: '', key: '' },
      checkBusiness: { id: '', key: '' }
    };
  },
  methods: {
    navCheckChange(item, type) {
      if (type === '0') {
        this.checkCustom.key = item.key == this.checkCustom.key ? '' : item.key;
        this.checkCustom.id = item.Id == this.checkCustom.id ? '' : item.Id;
      } else {
        this.checkBusiness.key = item.key == this.checkBusiness.key ? '' : item.key;
        this.checkBusiness.id = item.Id == this.checkBusiness.id ? '' : item.Id;
      }

      this.$emit('sideNavClick', [
        { type: '0', id: this.checkCustom.id },
        { type: '1', id: this.checkBusiness.id }
      ]);
    }
  }
};
</script>
<style lang="scss" scoped>
.side {
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s;
  &.open {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: background 0.3s;
    .side-nav {
      width: 250px;
      & > .side-bar {
        opacity: 0;
        z-index: -1;
      }
      .side-nav__list {
        width: 100%;
        opacity: 1;
      }
    }
  }
}
.side-nav {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 48px;
  background: #444;
  z-index: 5;
  overflow: hidden;
  height: 100%;
  transition: width 0.5s, opacity 0.3s;
  &__list {
    width: 0;
    padding-left: 50px;
    opacity: 0;
    transition: width 0.5s;
    dt,
    dd {
      margin: 0;
      line-height: 34px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    dt {
      padding-top: 50px;
      padding-bottom: 15px;
      color: lighten($defaultColor, 30%);
      font-size: 26px;
    }
    dd {
      position: relative;
      margin: 5px 0;
      padding: 3px 0;
      line-height: 24px;
      color: $defaultColor;
      cursor: pointer;
      font-size: 16px;
      transition: all 0.3s;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        content: '';
        background: $red;
        transition: width 0.3s;
      }
      &.active,
      &:hover {
        color: #fff;
        padding-left: 15px;
        &::before {
          width: 90%;
        }
      }
      span {
        position: relative;
        z-index: 2;
      }
    }
  }
}
.side-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 26px;
  overflow: hidden;
  cursor: pointer;
  transform: translateY(-50%) translateX(-50%);
  transition: opacity 0.3s;
  & > span {
    display: block;
    height: 3px;
    margin: 6px 0;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
  }
}
@media (max-width: 1440px) {
  .side-nav {
    dt {
      padding-top: 40px;
      padding-bottom: 3px;
      font-size: 22px;
    }
    dd {
      line-height: 20px;
      font-size: 15px;
    }
  }
}
</style>
