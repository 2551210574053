import axios from 'axios';
export default {
  data() {
    return {
      swiperData: []
    };
  },
  watch: {
    typeId() {
      // 首页案例分类
      this.getSwiperData();
    }
  },
  methods: {
    sideNavClick(arr) {
      // 案例more分类
      this.getSwiperData(arr);
    },
    getSwiperData(arr) {
      const params = { PageIndex: 1, PageSize: this.PageSize || 6 };
      if (this.typeId) {
        params.CustomerId = this.typeId;
      }
      if (arr) {
        if (arr[0].id != '') params.CustomerId = arr[0].id;
        if (arr[1].id != '') params.BusinessTypeId = arr[1].id;
      }
      axios.get('/api/Cases/Page', { params }).then(rs => {
        this.swiperData = [];
        if (rs.data.Success) {
          let rows = rs.data.Data.Data;
          rows.forEach(ele => {
            const imgSrc = JSON.parse(ele.ImgAddress);
            const { Id: id, Title: tit, Subtitle: subtit } = ele;
            this.swiperData.push({
              ...ele,
              id,
              src: imgSrc.img1,
              hoverSrc: imgSrc.img2,
              tit,
              subtit
            });
          });
        }
      });
    }
  },
  mounted() {
    this.getSwiperData();
  }
};
