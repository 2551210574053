<template>
  <header class="header clearfix">
    <div class="logo">
      <img src="@/assets/images/logo.png" alt="logo" />
    </div>
    <home-nav class="fr" @menuItemClick="menuItemClick"></home-nav>
  </header>
</template>

<script>
import { homeNav } from "@/components/web/nav";
export default {
  name: "homeHeader",
  components: { homeNav },
  methods: {
    menuItemClick(item) {
      this.$emit("menuItemClick", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  left: 4.2%;
  right: 4.2%;
  top: 0;
  height: $headerHeight;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  user-select: none;
  z-index: 3;
}
.logo {
  display: inline-block;
  height: $headerHeight - 2px;
  line-height: $headerHeight - 2px;
  & > img {
    height: 100%;
    max-height: 67px;
    vertical-align: middle;
    -webkit-user-drag: none;
  }
}

@media (max-width: 1440px) {
  $headerHeight: 90px;
  .header {
    height: $headerHeight;
  }
  .logo {
    height: $headerHeight - 2px;
    line-height: $headerHeight - 2px;
    & > img {
      max-height: 45px;
    }
  }
}
</style>
