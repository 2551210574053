<template>
  <nav class="nav">
    <ul class="nav__menu" :style="{width:collapsenav?'540px':0}">
      <li
        v-for="item in navMenu"
        :key="item.id"
        :class="{active:item.isCheck}"
        @click="menuHandler(item)"
      >
        <a href="javascript:;">{{ item.menu }}</a>
      </li>
    </ul>
    <span class="nav__icon" :class="{'open':collapsenav}" @click="collapsenav=!collapsenav">
      <span></span>
      <span></span>
      <span></span>
    </span>
  </nav>
</template>

<script>
export default {
  name: "homeNav",
  data() {
    return {
      collapsenav: false,
      navMenu: [
        { id: 0, menu: "首页", isCheck: true },
        { id: 1, menu: "案例", isCheck: false },
        { id: 2, menu: "联系我们", isCheck: false },
      ],
    };
  },
  methods: {
    menuHandler(item) {
      this.navMenu.map((v) => {
        v.isCheck = v.menu == item.menu ? true : false;
      });

      this.$emit('menuItemClick',item)
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__icon {
    display: inline-block;
    width: 42px;
    transition: transform 0.3s;
    cursor: pointer;

    & > span {
      display: block;
      height: 4px;
      border-radius: 4px;
      background-color: #fff;
      opacity: 0.7;
      & + span {
        margin-top: 12px;
      }
    }

    &.open {
      transform: rotate(90deg);
    }
  }

  &__menu {
    display: inline-block;
    height: $headerHeight;
    overflow: hidden;
    transition: width 0.4s;

    li {
      display: inline-block;
      width: 150px;

      a {
        display: block;
        line-height: $headerHeight;
        opacity: 0.7;
        font-size: 20px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
      }

      &:hover a,
      &.active a {
        opacity: 1;
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 1440px) {
  $headerHeight: 90px;

  .nav {
    &__icon {
      width: 32px;
      & > span {
        & + span {
          margin-top: 8px;
        }
      }
    }

    &__menu {
      height: $headerHeight;
      li {
        a {
          line-height: $headerHeight;
          font-size: 18px;
        }

        &:hover a,
        &.active a {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
