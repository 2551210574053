<template>
  <footer class="footer">
    <div class="t">
      <img src="../../../assets/images/logo.png" alt />
    </div>
    <div class="c">
      <div class="c__l">
        <p>北京市丰台区丰台科技园汽车博物馆东路8号院金茂广场3号楼906</p>
        <p>深圳市福田区喜年中心A座1809</p>
        <p>广州市天河区华强路3-2号富力盈力大厦-北塔1303</p>
        <p>香港中环德辅道中130-132号大生银行大厦12楼1201</p>
      </div>
      <div class="c__r">
        <span>
          <img src="../../../assets/images/weixin.png" alt />
        </span>
        <span>
          <img src="../../../assets/images/weibo.png" alt />
        </span>
        <p>电话：020-88526087</p>
        <p>邮箱：hr@mixdo.net</p>
      </div>
    </div>
    <div class="btm">© 2020 MIXDO. All rights reserved <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备18032146号-1</a></div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  background: $red;
  // z-index: 11;

  & > div {
    padding-left: 8.4%;
    padding-right: 4.2%;
  }

  .t {
    padding-top: 2.8%;
  }

  .c {
    display: flex;
    padding-top: 3.3%;
    justify-content: space-between;

    &__r {
      position: relative;
      top: -3%;

      & > span {
        display: inline-block;
        width: 70px;
        line-height: 70px;
        border: 1px solid #e09690;
        text-align: center;
        border-radius: 50%;

        & + span {
          margin-left: 15px;
        }

        img {
          vertical-align: middle;
        }
      }
    }
  }

  .btm {
    height: 59px;
    margin-top: 2.9%;
    line-height: 59px;
    text-align: right;
    background: rgba(51, 51, 51, 0.5);
    color: rgba(234, 212, 212, 0.5);
  }

  @media (max-width: 1440px) {
    .t {
      img {
        height: 54px;
      }
    }
    .c {
      padding-top: 2.3%;

      &__r {
        & > span {
          width: 50px;
          line-height: 50px;

          img {
            height: 22px;
          }
        }
      }
    }

    .btm {
      height: 44px;
      margin-top: 1.9%;
      line-height: 44px;
    }
  }
}
</style>