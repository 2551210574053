<template>
  <div class="page5" id="page5">
    <!-- content  -->
    <div class="cont">
      <div class="l">
        <div class="l__mask" @mouseover="videoPlay($event)" @mouseout="videoStop($event)">
          <img class="normal" src="../../../../assets/images/img06.png" alt />
          <video
            width="100%"
            id="video"
            loop
            preload
            muted
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline="true"
            playsinline="true"
            src="https://zww1.oss-cn-shenzhen.aliyuncs.com/mixdo_protal/video/mixdo.mp4"
            class="hover"
          ></video>
        </div>
        <div class="l__cont">
          <h3>我们的团队</h3>
          <hr class="white-line" />
          <span>OUR TEAM</span>
        </div>
      </div>
      <div class="r">
        <ul>
          <li v-for="item in contactData" :key="item.id">
            <a href="javascript:void(0);">{{ item.text }}</a>
          </li>
          <li data-v-121adbaa class="last">
            <a data-v-121adbaa href="javascript:void(0);" class="com-more" @click="more">
              CONTACT US
              <span data-v-121adbaa class="icon"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="btm">© 2020 MIXDO. All rights reserved <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备18032146号-1</a></div>
    <footers class="page-footer" :class="{ show: showfooter == '1', hide: showfooter == '2' }"></footers>

    <div
      class="footer-place"
      @mousemove="showfooter = '1'"
      @mouseout="showfooter = '2'"
      :style="{ width: showfooter == 1 ? '80%' : '50%' }"
    ></div>
  </div>
</template>

<script>
import { footers } from '@/components/web/footers';

export default {
  name: 'page5',
  data() {
    return {
      page5Swiper: null,
      page5Height: 0,
      contactData: [
        { id: 0, text: '招商银行总行十年品牌合作伙伴；' },
        { id: 1, text: '招银理财成立至今年度品牌合作伙伴；' },
        { id: 2, text: '工商银行广州分行五年品牌合作伙伴；' },
        { id: 3, text: '青岛银行十年品牌合作伙伴；' },
        { id: 4, text: '华侨城集团五年年度品牌合作伙伴；' },
        { id: 5, text: '广州南粤银行品牌合作伙伴；' },
        { id: 6, text: '广州南粤银行品牌合作伙伴；' }
      ],
      showfooter: ''
    };
  },
  components: { footers },
  methods: {
    more() {
      this.$store.dispatch('setPagefiveJoinusShowC', true);
    },
    videoPlay(even) {
      even.target.tagName == 'VIDEO' && even.target.play();
    },
    videoStop(even) {
      even.target.tagName == 'VIDEO' && even.target.pause();
    }
  }
};
</script>

<style lang="scss" scoped>
.page5 {
  height: 100%;
  overflow: hidden;
}
.cont {
  display: flex;
  height: 95%;
  overflow: hidden;

  .l {
    position: relative;
    width: 100%;
    flex-basis: 100%;
    &__mask {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0.9;
      transition: opacity 0.5s;
      & > .hover {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: opacity 0.5s;
        transition-delay: -0.3s;
      }
      &:hover {
        .normal {
          opacity: 0;
        }
        .hover {
          opacity: 1 !important;
        }
      }
    }
    &__mask > img {
      vertical-align: middle;
      width: 100%;
    }

    &__cont {
      position: absolute;
      left: 0;
      top: 0;
      padding: 13% 0 0 6%;

      h3 {
        margin: 0;
        font-size: 60px;
      }

      hr {
        width: 80px;
      }

      & > span {
        font-size: 16px;
      }

      & > span {
        display: block;
        padding: 0.5% 0;
        opacity: 0.6;
      }
    }
  }

  .r {
    display: flex;
    width: 0%;
    flex-basis: 0%;
    align-items: center;

    ul {
      padding-left: 30px;
      padding-top: 80px;

      li:not(.last) a {
        position: relative;
        display: block;
        line-height: 80px;
        color: $defaultColor;
        font-size: 26px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          color: $color;
        }
      }

      .last {
        padding-top: 60px;
        a {
          justify-content: center;
        }
      }
    }
  }
}

.btm {
    width: 100%;
    height: 59px;
    margin-bottom: 1%;
    padding-right: 5%;
    line-height: 59px;
    text-align: right;
    background: rgba(51, 51, 51, 0.5);
    color: rgba(234, 212, 212, 0.5);
  }

  @media (max-width: 1440px) {
    .t {
      img {
        height: 54px;
      }
    }
    .c {
      padding-top: 2.3%;

      &__r {
        & > span {
          width: 50px;
          line-height: 50px;

          img {
            height: 22px;
          }
        }
      }
    }

    .btm {
      height: 44px;
      // margin-top: 1.9%;
      line-height: 44px;
    }
  }
.page-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100%;
  transition: bottom 1s;
}

// 动画
.swiper-slide-active > .page5 {
  .cont {
    & > .l {
      animation: widthPer100To66 1s forwards;
      animation-delay: 0.5s;
    }

    & > .r {
      animation: widthPer0To34 1s forwards;
      animation-delay: 0.5s;
    }
  }
  .page-footer {
    bottom: 0;
    transition-delay: 3s;
    animation: fadeOutDown 1s forwards;
    animation-delay: 7s;
    &.show {
      animation: fadeOutUp 1s forwards;
    }
    &.hide {
      animation: fadeOutDown 1s forwards;
    }
  }
}

@media (max-width: 1440px) {
  .cont {
    .l {
      &__cont {
        padding-top: 10%;
        h3 {
          font-size: 50px;
        }
        & > span {
          font-size: 14px;
        }
      }
    }

    .r {
      ul {
        padding-top: 60px;

        li:not(.last) a {
          line-height: 60px;
          font-size: 20px;
        }
        .last {
          padding-top: 30px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

.footer-place {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 300px;
  // z-index: 9;
}
</style>